import * as Sentry from '@sentry/nuxt';

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: useRuntimeConfig().public.sentry.dsn,

  integrations: [Sentry.replayIntegration()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  //Session replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
